var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"calculation"},[_c('h6',{staticClass:"calculation__title"},[_vm._v(" "+_vm._s(_vm.$cookie.get("lang") === "Ru" ? `Расчет стоимости доставки` : `Жеткізу құнын есептеу`)+" ")]),_c('div',{staticClass:"calculation__phone calculation__item"},[_c('app-input',{directives:[{name:"mask",rawName:"v-mask",value:('+7 (###) ###-##-##'),expression:"'+7 (###) ###-##-##'"}],attrs:{"title":_vm.lang === 'Ru' ? 'Телефон' : 'Телефон',"text":_vm.item.phone},model:{value:(_vm.item.phone),callback:function ($$v) {_vm.$set(_vm.item, "phone", $$v)},expression:"item.phone"}})],1),_c('div',{staticClass:"calculation__address calculation__item"},[_c('h6',{staticClass:"calculation__item-title"},[_vm._v(" "+_vm._s(_vm.lang === "Ru" ? "Адрес" : "Адрес")+" ")]),_c('div',{staticClass:"calculation__address__list"},_vm._l((_vm.addresses),function(address){return _c('div',{key:address.id,staticClass:"calculation__address__item",class:{
          'calculation__address__item-active':
            _vm.item.user_address == address.id,
        },attrs:{"id":address.id},on:{"click":_vm.selectAdress}},[_c('p',{staticClass:"calculation__address__item-label calculation__item-text",class:{
            'calculation__item-text__active': _vm.item.user_address == address.id,
          },attrs:{"id":address.id}},[_vm._v(" "+_vm._s(address.country.name)+" г."+_vm._s(address.city.name)+" ")]),_c('p',{staticClass:"calculation__address__item-label calculation__item-text",class:{
            'calculation__item-text__active': _vm.item.user_address == address.id,
          },attrs:{"id":address.id}},[_vm._v(" "+_vm._s(address.street)+" "+_vm._s(address.home)+" ")])])}),0)]),_c('div',{staticClass:"calculation__delivery calculation__item"},[_c('h6',{staticClass:"calculation__item-title"},[_vm._v(" "+_vm._s(_vm.lang === "Ru" ? "Способ доставки" : "Жеткізу әдісі")+" ")]),_c('div',{staticClass:"calculation__delivery__list"},[_c('div',{staticClass:"calculation__delivery__item",class:{
          'calculation__delivery__item-active':
            _vm.item.delivery_option == 'cdek',
        },attrs:{"id":"cdek"},on:{"click":_vm.selectDelivery}},[_c('img',{attrs:{"src":require("@/assets/basket/cdek_delivery.png"),"alt":"","id":"cdek"}})]),_c('div',{staticClass:"calculation__delivery__item",class:{
          'calculation__delivery__item-active':
            _vm.item.delivery_option == 'yandex_delivery',
        },attrs:{"id":"yandex_delivery"},on:{"click":_vm.selectDelivery}},[_c('img',{attrs:{"src":require("@/assets/basket/yandex_delivery.png"),"alt":"","id":"yandex_delivery"}})]),_c('div',{staticClass:"calculation__delivery__item",class:{
          'calculation__delivery__item-active':
            _vm.item.delivery_option == 'self_delivery',
        },attrs:{"id":"self_delivery"},on:{"click":_vm.selectDelivery}},[_c('span',{staticClass:"calculation__item-text",attrs:{"id":"self_delivery"}},[_vm._v(_vm._s(_vm.lang === "Ru" ? "Самовывоз" : "Ала кету"))])])])]),_c('div',{staticClass:"calculation__total"},[_c('div',{staticClass:"calculation__total-title"},[_vm._v(" "+_vm._s(_vm.lang === "Ru" ? `Итого к оплате с доставкой:` : `Жеткізу арқылы жалпы төлем:`)+" ")]),_c('div',{staticClass:"calculation__total-price"},[_vm._v(" "+_vm._s(_vm.total_price)+" "+_vm._s(_vm.currency)+" ")])]),(_vm.errorMessage !== '')?_c('label',[_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e(),_c('div',{staticClass:"calculation__btn",class:{ calculation__error: _vm.errorMessage !== '' },attrs:{"disabled":_vm.isLoader},on:{"click":_vm.handleClicked}},[(!_vm.isLoader)?_c('span',[_vm._v(" "+_vm._s(_vm.lang === "Ru" ? `Перейти к оплате` : `Төлемге өтіңіз`)+" ")]):_c('span',[_c('div',{staticClass:"loader"})])]),_c('router-link',{staticClass:"calculation__info__btn",attrs:{"to":"/payment"}},[_vm._v(" "+_vm._s(_vm.lang === "Ru" ? "Информация об оплате" : "Төлем туралы ақпарат")+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }